import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { BrowserRouter as Router } from "react-router-dom";

import AppContainer from "./containers/App";
import reducer from "./reducers";

import "./App.css";

let middlewares = [thunk];

if (process.env.NODE_ENV !== "production") {
  middlewares = [...middlewares, logger];
}

const store = createStore(
  reducer,
  undefined,
  compose(
    applyMiddleware(...middlewares)
  ),
);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContainer />
      </Router>
    </Provider>
  );
}

export default App;
