import { API_HOST, WEBHOOK_HOST } from "../misc/settings";

export function getUsers() {
  return () => {
    const url = `${WEBHOOK_HOST}/monitor/country`;
    const method = "GET";
    const headers = new Headers({
      "Accept": "application/json",
    });

    return fetch(url, { method, headers })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);

        return response.json();
      })
      .then((stats) => {
        return stats;
      })
      .catch(() => {
        return Promise.resolve({ total_users: 1454, total_countries: 77 });
      });
  };
}

export function getActivity() {
  return () => {
    const url = `${API_HOST}/admin/activity/latest`;
    const method = "GET";
    const headers = new Headers({
      "Accept": "application/json",
    });

    return fetch(url, { method, headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        }

        return response.json();
      })
      .then((stats) => {
        return stats;
      })
      .catch(() => {
        return Promise.resolve({ activity: 64000, duration: 14296967 });
      });
  };
}
