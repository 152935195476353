import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Route } from "react-router";

import {
  Button,
  Container,
  Menu,
  Segment,
  Image,
} from "semantic-ui-react";
import Homepage from "./Homepage";
import logoInversed from "../images/eosurgical_logo_inversed.png";


function App() {
  return (
    <div className="App">

      <Segment
        inverted
        color="green"
        textAlign="center"
        vertical
        >
        <Container fluid style={styles.margins}>
          <Menu inverted stackable secondary size="large">
            <Menu.Item>
              <Image
                src={logoInversed}
                size="medium"
                style={{ marginRight: "1.5em" }}
              />
            </Menu.Item>
            <Menu.Item position="right">
              <Button
                as="a"
                inverted
                style={{ marginLeft: "0.5em" }}
                href="mailto:support@eosurgical.com?subject=SurgTrac Analytics query"
              >
                Get in touch
              </Button>
            </Menu.Item>
          </Menu>
        </Container>
      </Segment>

      <Route exact path="/" component={Homepage} />
    </div>
  );
}

const styles = {
  margins: {
    paddingRight: "2em",
    paddingLeft: "2em",
  }
};

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = () => {
  return {
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
