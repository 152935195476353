import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LineChart, Line, ResponsiveContainer } from "recharts";
import CountUp from "react-countup";
import moment from "moment";
import {
  Container, List, Header, Segment, Grid, Divider, Placeholder,
  Image, Button, Card,
} from "semantic-ui-react";

import { secondary } from "../misc/colors";
import metricsPic from "../images/metrics.jpg";
import {
  getUsers as getUsersAction,
  getActivity as getActivityAction,
} from "../actions/stats";

function numberWithCommas(number) {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function secondsToHMS(seconds) {
  if (Number(seconds) === 0) {
    return "-";
  }

  const h = Math.floor(Number(seconds) / 3600);
  const m = Math.floor((Number(seconds) % 3600) / 60);
  const s = Math.floor(Number(seconds) % 3600 % 60);

  const hDisplay = h > 0 ? `${h}h` : "";
  const mDisplay = m > 0 ? `${m}m` : "";
  const sDisplay = s > 0 ? `${s}s` : "";
  if (h > 0) {
    return `${numberWithCommas(hDisplay)} ${numberWithCommas(mDisplay)}`;
  }
  return `${numberWithCommas(mDisplay)} ${numberWithCommas(sDisplay)}`;
}

/*
  This is the Homepage - landing page
*/
function Homepage(props) {
  const { getUsers, getActivity } = props;
  const [users, setUsers] = useState({});
  const [activity, setActivity] = useState({});
  const [loading, setLoading] = useState(true);

  const chartData = [
    {
      name: "Page A", uv: 4000, pv: 2400, amt: 2400
    },
    {
      name: "Page B", uv: 3000, pv: 1398, amt: 2210
    },
    {
      name: "Page C", uv: 2000, pv: 7000, amt: 2290
    },
    {
      name: "Page D", uv: 2780, pv: 3908, amt: 2000
    },
    {
      name: "Page E", uv: 1890, pv: 4800, amt: 2181
    },
    {
      name: "Page F", uv: 2390, pv: 3800, amt: 2500
    },
    {
      name: "Page G", uv: 3490, pv: 4300, amt: 2100
    },
  ];

  useEffect(() => {
    Promise.all([
      getUsers().then((stats) => setUsers(stats)),
      getActivity().then((stats) => setActivity(stats)),
    ])
      .then(() => setLoading(false));
  }, []);

  return (
    <div>
      <Grid stackable centered columns={2} padded="horizontally">
        <Grid.Row color="green">
          <Segment
            inverted
            color="green"
            textAlign="center"
            vertical
          >
            <Container text>
              <Header
                as="h1"
                content="SurgTrac Analytics"
                inverted
                style={{
                  fontSize: "4em", fontWeight: "normal", marginBottom: 0, marginTop: "1em"
                }}
              />
              <Header
                as="h2"
                content="Where surgery meets data"
                inverted
                style={{ fontSize: "1.7em", fontWeight: "normal" }}
              />

              <Container textAlign="center" style={styles.topBuffer}>
                <ResponsiveContainer width="90%" height={300}>
                  <LineChart width={500} height={500} data={chartData} style={styles.chart}>
                    <Line type="monotone" dataKey="uv" stroke="white" />
                    <Line type="monotone" dataKey="pv" stroke="white" />
                    <Line type="monotone" dataKey="amt" stroke="white" />
                  </LineChart>
                </ResponsiveContainer>
              </Container>

            </Container>
          </Segment>
        </Grid.Row>

        <Grid.Row>
          <Container>
            <Divider horizontal>
              <Header as="h1">
                <CountUp
                  className="account-balance"
                  start={0}
                  end={130000}
                  duration={3.75}
                  useEasing
                  useGrouping
                  separator=" "
                  suffix=" +"
                />
                <Header.Subheader>
                  Tasks practised
                </Header.Subheader>
              </Header>
            </Divider>
          </Container>
        </Grid.Row>

        <Grid.Row>
          <Container textAlign="center" style={styles.liveStats} fluid>
            <Header as="h2">Real-time data</Header>
            <Card.Group stackable itemsPerRow={4}>
              <Card>
                {loading && (
                  <Placeholder style={{ margin: 20 }}>
                    <Placeholder.Header>
                      <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length="medium" />
                      <Placeholder.Line length="short" />
                    </Placeholder.Paragraph>
                  </Placeholder>
                )}
                {!loading && (
                  <Card.Content>
                    <Card.Header>Global activities</Card.Header>
                    <Card.Description>
                      <Header as="h1" style={styles.primaryTitle}>{activity.activity}</Header>
                    </Card.Description>
                  </Card.Content>
                )}
              </Card>
              <Card>
                {loading && (
                  <Placeholder style={{ margin: 20 }}>
                    <Placeholder.Header>
                      <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length="medium" />
                      <Placeholder.Line length="short" />
                    </Placeholder.Paragraph>
                  </Placeholder>
                )}
                {!loading && (
                  <Card.Content>
                    <Card.Header>Global time</Card.Header>
                    <Card.Description>
                      <Header as="h1" style={styles.primaryTitle}>{secondsToHMS(activity.duration)}</Header>
                    </Card.Description>
                  </Card.Content>
                )}
              </Card>
              <Card>
                {loading && (
                  <Placeholder style={{ margin: 20 }}>
                    <Placeholder.Header>
                      <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length="medium" />
                      <Placeholder.Line length="short" />
                    </Placeholder.Paragraph>
                  </Placeholder>
                )}
                {!loading && (
                  <Card.Content>
                    <Card.Header>Active users</Card.Header>
                    <Card.Description>
                      <Header as="h1" style={styles.primaryTitle}>{users.total_users}</Header>
                    </Card.Description>
                  </Card.Content>
                )}
              </Card>
              <Card>
                {loading && (
                  <Placeholder style={{ margin: 20 }}>
                    <Placeholder.Header>
                      <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length="medium" />
                      <Placeholder.Line length="short" />
                    </Placeholder.Paragraph>
                  </Placeholder>
                )}
                {!loading && (
                  <Card.Content>
                    <Card.Header>Locations</Card.Header>
                    <Card.Description>
                      <Header as="h1" style={styles.primaryTitle}>{`${users.total_countries} Countries`}</Header>
                    </Card.Description>
                  </Card.Content>
                )}
              </Card>
            </Card.Group>
          </Container>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Container style={styles.padding}>
              <Header as="h2">
                Skills Progression
                <Header.Subheader>
                  SurgTrac offers useful insights on how candidates improve over time
                </Header.Subheader>
              </Header>
              <p>
                {"Our instrument tracking software, SurgTrac, captures the movements of instruments while our users perform tasks."}
                {" All this data is saved into our system and can be used to conduct diverse research. For example SurgTrac uses the data to generate natural language feedback for users after they complete a task."}
              </p>

              <Container textAlign="center" style={{ marginTop: 50 }}>
                <Button
                  primary
                  as="a"
                  href="https://app.surgtrac.com"
                  target="_blank"
                  size="big"
                >
                  Check out SurgTrac
                </Button>
              </Container>
            </Container>
          </Grid.Column>

          <Grid.Column>
            <Container style={styles.padding}>
              <Header as="h2">
                Metrics
                <Header.Subheader>
                  {"SurgTrac generates 7 different metrics for each hand"}
                </Header.Subheader>
              </Header>
              <Image
                centered
                size="massive"
                src={metricsPic}
              />
            </Container>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Container>
            <Divider horizontal>
              <Header as="h1">
                {"Hundreds of users"}
                <Header.Subheader>
                  {"Practising every day"}
                </Header.Subheader>
              </Header>
            </Divider>
          </Container>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Container style={styles.padding}>
              <Image
                centered
                size="massive"
                src="https://cdn.shopify.com/s/files/1/0662/5771/files/eoSales_Map_Jan_2015.png?9370348715982145077"
              />
            </Container>
          </Grid.Column>

          <Grid.Column>
            <Container style={styles.padding}>
              <Header as="h2">
                {"Users from 90+ countries"}
                <Header.Subheader />
              </Header>
              <p>
                {"The annonymised data is recorded in real-time after each user is completing a task using the SurgTrac app."}
                {" The SurgTrac curriculum offers "}
                <strong>3 courses</strong>
                {" each consisting of "}
                <strong>6 modules</strong>
                {" that they can practise. "}
              </p>
              <p>{"You can have a look at the courses by cicking the links below. "}</p>

              <Button.Group fluid size="large" style={styles.topBuffer}>
                <Button primary as="a" href="https://app.surgtrac.com/#/courses-information">Core</Button>
                <Button primary as="a" href="https://app.surgtrac.com/#/courses-information">Advanced</Button>
                <Button primary as="a" href="https://app.surgtrac.com/#/courses-information">Elite</Button>
              </Button.Group>
            </Container>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Container>
            <Divider horizontal>
              <Header as="h1">
                Research
                <Header.Subheader>
                  Access the data using our API
                </Header.Subheader>
              </Header>
            </Divider>
          </Container>
        </Grid.Row>

        <Grid.Row columns={1} id="contact">
          <Grid.Column>
            <Container text textAlign="center">
              <Header as="h2">
                {"Running a research project or want to build something awesome with the data?"}
              </Header>

              <Button
                primary
                as="a"
                style={{ marginLeft: "0.5em", marginBottom: 50, marginTop: 50 }}
                href="mailto:support@eosurgical.com?subject=SurgTrac Analytics query"
                size="big"
              >
                Tell us about your project
              </Button>
            </Container>
          </Grid.Column>
        </Grid.Row>

      </Grid>

      <Segment inverted vertical style={styles.footer}>
        <Container>
          <Grid divided inverted stackable centered>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="About" />
                <List link inverted>
                  <List.Item as="a" href="https://www.eosurgical.com/" target="_blank">eoSurgical</List.Item>
                  <List.Item as="a" href="https://www.eosurgical.com/pages/contact-us" target="_blank">Contact Us</List.Item>
                  <List.Item as="a" href="https://www.eosurgical.com/pages/team" target="_blank">Our Team</List.Item>
                  <List.Item as="a" href="https://www.eosurgical.com/pages/discover-surgtrac-1" target="_blank">SurgTrac</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="Products" />
                <List link inverted>
                  <List.Item as="a" href="https://www.eosurgical.com/collections/simulators-individual" target="_blank">eoSim simulators</List.Item>
                  <List.Item as="a" href="https://www.eosurgical.com/collections/instruments" target="_blank">Instruments</List.Item>
                  <List.Item as="a" href="https://www.eosurgical.com/collections/surgtrac-software" target="_blank">Software</List.Item>
                </List>
              </Grid.Column>
              <p>
                <a
                  href="https://www.eosurgical.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  &copy;
                  {moment().year()}
                  {" "}
                  eoSurgical, Ltd.
                </a>
              </p>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </div>
  );
}

const styles = {
  header: {
    backgroundColor: secondary,
  },
  liveStats: {
    paddingLeft: 20,
    paddingRight: 20,
    margin: 20,
  },
  segmentSpacing: {
    flex: 1,
    minHeight: 1000,
    margin: 0,
    border: "white",
  },
  bigSegment: {
    height: 1400,
  },
  chart: {
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  topBuffer: {
    marginTop: 50,
  },
  padding: {
    padding: 20,
  },
  footer: {
    padding: "5em 0em",
    marginTop: 30,
  },
  subscribeForm: {
    fontSize: 20,
  },
  primaryTitle: {
    color: secondary,
  },
};

Homepage.propTypes = {
  getUsers: PropTypes.func.isRequired,
  getActivity: PropTypes.func.isRequired,
};

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getUsersAction()),
    getActivity: () => dispatch(getActivityAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
